<template>
  <v-dialog v-model="mostrar" persistent width="400">
    <v-card>
      <v-toolbar color="error" dark dense flat>
        <v-toolbar-title>Confirmar</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-2">
        <span
          >¿Está seguro de eliminar el examen? Esta acción no se puede
          revertir.</span
        >
        <v-row dense class="mt-2">
          <v-col cols="12">
            <v-btn
              small
              color="secondary"
              outlined
              class="mr-2"
              :disabled="loading"
              @click="$emit('cancelar')"
            >
              <span>Cancelar</span>
            </v-btn>
            <v-btn
              small
              color="error"
              dark
              :loading="loading"
              @click="eliminarExamen()"
            >
              <v-icon small class="mr-2">mdi-delete</v-icon>
              <span>Eliminar examen</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { eliminarExamen } from "./examenes.service";

export default {
  props: {
    mostrar: { type: Boolean, default: false },
    idExamen: { type: String, required: true },
  },

  data: () => ({
    loading: false,
  }),

  methods: {
    async eliminarExamen() {
      this.loading = true;

      try {
        const serverResponse = await eliminarExamen(this.idExamen);
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.$emit("examenEliminado", serverResponse.examen);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
